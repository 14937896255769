<template>

    <main>

        <Social />

        <div id="hero-intro-text">
            <img src="./assets/images/henson-for-iowa-header-1.svg" />
        </div>

        <div id="heroine-photo">
            <img src="./assets/images/heroine-photo.jpg" />
        </div>

        <div id="blue-area">

            <div class="logo-text">
                <img src="./assets/images/henson-for-iowa-logo-text.svg" />
            </div>

            <div class="state-logo">
                <img src="./assets/images/henson-state-logo.svg" />
            </div>

            <h3>Iowa House District&nbsp;36</h3>
            <p>Shannon is seeking the Democratic nomination for Iowa House District 36 in the June&nbsp;7,&nbsp;2022&nbsp;primary.</p>

            <div class="district-map bar align:center">
                <a href="docs/plan-2-house-36.pdf" class="button color:white shape:round" bg target="_blank">
                    <span style="color: var(--dark-blue)">View District Map</span>
                </a>
            </div>

        </div>

        <div class="buttonz bar align:stack">
            <a href="https://secure.actblue.com/donate/henson-for-iowa-1" target="_blank" class="button style:henson-blue shape:round" bg>
                <span txt>Donate Now</span>
            </a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScJJvffe6QOvEvK8ohrI6DM_GTwbjfU7cm-jfzizoJ4z7yCdw/viewform" target="_blank" class="button style:henson-light shape:round" bg>
                <span txt>Volunteer</span>
            </a>
            <a href="https://mymvd.iowadot.gov/Account/Login?ReturnUrl=%2fVoterRegistration" class="button style:henson-lighter shape:round" target="_blank" bg>
                <span txt>Register to Vote</span>
            </a>
        </div>

        <div class="video">
            <iframe src="https://www.youtube.com/embed/eFmkDN5cW_4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div id="bio">
            <!-- <h4>About Me</h4> -->
            <!-- <p>I grew up in southeast Iowa and graduated from Keokuk High School in 2004. After high school, I left Keokuk to attend the University of Iowa where I studied Political Science, Spanish, and English. I have called Des Moines home since 2008 when I moved here to attend Drake University Law School. While at Drake, I completed the Legislative Practice Certificate Program which allowed me to gain first-hand experience at the Capitol through internships.&nbsp;</p> -->
            <!-- <p>Though I worked at the Capitol for a couple years near the middle of my career, most of my legal career has been spent representing the interests of injured Iowans and Iowans who have been wronged by insurance companies. Helping my clients get through some of the most difficult times in their lives has given me a unique perspective on the struggles Iowans face.&nbsp;</p> -->
            <!-- <p>I am a proud Iowan. This state has a progressive history that we can be proud of, but over the last several years we have seen witnessed a sharp turn in the discourse at the state house. &nbsp; It has been disheartening to see our Republican-controlled legislature waging culture wars and attack the most vulnerable in our society instead of focusing on policies that help Iowans.&nbsp;</p> -->
            <h4>Why I'm Running</h4>
            <p>I am a proud Iowan. We have a progressive history that we can be proud of, but over the past several years we've witnessed a sharp turn in the tone of discourse at the state house. Watching our Republican-controlled legislature wage culture wars and attack the most vulnerable in our society - instead of focusing on policies that help Iowans - has left many of us disheartened.</p>
            <p><img src="./assets/images/bio-photo-02.jpg" /></p>
            <p>But we need to take heart once more, to build back an Iowa that we can all be proud of. Iowa's legislators have a very real responsibility to solve problems and improve lives.</p>
            <p>I've spent my career fighting for everyday Iowans, and I'm ready to take this fight to the Capitol. That's why I'm running for Iowa House District 36. I will bring a strong voice for my community and advance causes that strengthen our state.</p>
            <p><img src="./assets/images/bio-photo-04.jpg" /></p>

            <h4>My Background</h4>
            <p>Growing up in a mid-sized city in southeast Iowa helped me develop a keen sense of the importance of community. After graduating from Keokuk High School, I sought to understand more about my place in the larger world community, studying Spanish, English, and Political Science. I have called Des Moines home since 2008, when I moved here to attend Drake University Law School. There, I gained first-hand experience with Iowa's legislative processes, through the Legislative Practice Certificate program.</p>
            <p>I've spent most of my legal career representing the interests of injured Iowans and Iowans who have been wronged by insurance companies. Listening to and standing up for folks going through some of the most difficult times in their lives has given me a unique perspective on the struggles Iowans face.</p>
            <div class="henson-in-iowa">
                <img src="./assets/images/henson-in-iowa.png" />
            </div>
            <h4>Issues</h4>
            <p>
                <strong>Public Schools</strong><br />
                <span>Strong public schools are critical to the future of this state. Iowa - and citizens across the US - have long lauded our public school system as first in the nation. However, in the past several years, our students, teachers, paraprofessionals, and administrators have struggled in the face of our Republican-controlled legislature attacking, rather than supporting, our public schools.</span>
                <br /><br />
                <span>We can get our schools back on track if we invest in them, instead of adding roadblocks to their success. We must pay our teachers a competitive salary so we can attract new teachers and retain current ones. We need to provide our schools with the resources they need. Prioritizing public education will advance our state in so many ways. It will keep young families here and attract new people to our state. When I'm elected, ensuring adequate funding for public schools will be one of my main priorities.</span>
            </p>
            <p><strong>LGBTQ Iowans</strong><br />I will support LGBTQ Iowans and vehemently resist any efforts to make changes to the Iowa Civil Rights Act that would exclude protections for LGBTQ Iowans. I will also fight hard against efforts to interfere with the rights of trans Iowans, including the right of women and girls to participate in school sports.</p>
            <p><strong>Social and Racial Justice</strong><br />Every Iowan deserves a fair shake regardless of the color of their skin, their gender identity or sexual orientation, or the balance in their bank account. From fair tax policies to policing, if elected, I promise to work to protect the interests of every member of our community.</p>
            <p><strong>Protecting Iowa Workers</strong><br />From teachers' unions to trade unions, I will work to protect the interests of Iowans by voting to promote fair wages and protect the rights of union workers. We must roll back the changes to Chapter 20 and expand collective bargaining rights.</p>
            <p><strong>Unions</strong><br />From teachers' unions to trade unions, I will work to protect the interests of Iowans by voting to promote fair wages and protect the rights of union workers.</p>
            <p><strong>Reproductive Freedom</strong><br />Like many of us, I can't believe we are still talking about this, but the GOP keeps bringing it up. Any attack on reproductive rights is an attack on women, and I fully support a woman's right to choose what to do with her body. It's now more important than ever that we elect champions for reproductive justice. As your elected representative, I will oppose restrictions on abortions and efforts to limit access to reproductive health care.</p>
            <p><strong>Climate</strong><br />Climate change is real, and it is threat to our future. Iowa can play a pivotal role in combating climate change, and we should. It will mean continuing to find and support regenerative agriculture, seeking real solutions to energy sources, and leading the way in reducing our dependence on fossil fuels.</p>
            <p><strong>Legalization of Cannabis</strong><br />It's time we legalize and regulate marijuana for recreational use. Most Iowans support the legalization of marijuana. Other states who've taken this step are putting the added revenue to good use. Iowa should too. Legalizing marijuana is also a social justice issue. It is widely known that white people are just as likely to use marijuana as folks of other races, but far less likely to face legal repercussions that will follow them through their life.</p>
            <p><strong>Protecting voting rights</strong><br />The right to vote is fundamental to our democracy. Yet, in recent years we have seen Republicans across the country and even in Iowa attempt to roll back voting rights. Such measures include placing restrictions on the collection of absentee ballots and even disallowing people from handing out food and water in long voting lines. These baseless and unfair laws make it harder for Iowans to cast their vote. If elected, I will oppose unfair restrictions on the right to vote and ensure we have fair voting laws that do not place unnecessary barriers in front of voters.</p>

            <div id="endorsements">

                <h2>Endorsements</h2>

                <h5>Elected Officials</h5>

                <div class="photos">

                    <EndorsementPhoto photo="/endorsements/bruce-braley.jpg" name="Bruce Braley" title="Former U.S. Representative" />
                    <EndorsementPhoto photo="/endorsements/bruce-hunter.jpg" name="Bruce Hunter" title="Iowa House Representative" />
                    <EndorsementPhoto photo="/endorsements/emily-webb.jpg" name="Emily Webb" title="Broadlawns Board of Trustees" />
                    <EndorsementPhoto photo="/endorsements/phil-wise.jpg" name="Phil Wise" title="Former Iowa House Representative" />

                </div>

                <h5>Community Leaders</h5>

                <div class="photos">

                    <EndorsementPhoto photo="/endorsements/rob-johnson.jpg" name="Rev. Rob Johnson" title="" />
                    <EndorsementPhoto photo="/endorsements/mitch-henry.jpg" name="Mitch Henry" title="" />
                    <EndorsementPhoto photo="/endorsements/cathy-jury.jpg" name="Cathy Jury" title="" />

                </div>

                <h5>Organizations</h5>

                <p class="organizations">
                    <span><img src="./assets/images/star-white-2.svg" /> <span>Vote Pro Choice</span></span>
                    <span><img src="./assets/images/star-white-2.svg" /> <span>Justice for All PAC, Iowa Association of justice</span></span>
                    <span><img src="./assets/images/star-white-2.svg" /> <span>Iowa Unity Coalition</span></span>
                </p>

            </div>

            <p><img src="./assets/images/bio-photo-03.jpg" /></p>
        </div>

        <Social />

        <sub>
            &copy; {{ year }} Henson for Iowa All rights reserved <br />
            Site maintained and paid for by Henson for Iowa
        </sub>

    </main>

</template>

<script>
import Social from './components/Social.vue'
import EndorsementPhoto from './components/EndorsementPhoto.vue'

export default {
    name: 'App',
    components: {
        Social,
        EndorsementPhoto,
    },

    data: () => ({
        year: new Date,
    }),

    mounted () {
        console.log('env.vue_app_base_url', process.env.VUE_APP_BASE_URL);

        let d = new Date();
        this.year = d.getUTCFullYear();
    }
}
</script>

<style lang="scss">

    img {
        width: 100%;
    }

    #hero-intro-text {
        padding: 1em;
        text-align: center;

        img {
            width: 70vw;
            max-width: 280px;
        }
        
    }

    #heroine-photo {
        text-align: center;
        margin: 2em 0 3px 0;
        
        img {
            width: 100%;
            max-width: 600px;
        }
    }

    #endorsements {

        padding: 0 2em;

        h2 {
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
        }

        h5 {
            font-weight: 400;
            text-align: center;
        }

        p.organizations {
            text-align: center;
            font-weight: 400;

            > span {
                display: inline-grid;
                place-items: center;
                grid-auto-flow: column;
                padding: .3rem .6rem;
                background: var(--light-blue);
                color: #fff;
                border-radius: 3rem;
                margin: 0 .5rem .5rem 0;

                img {
                    width: 16px;
                    margin-right: .3rem;
                }
            }
        }

        .photos {

            text-align: center;
            margin: 2em 0 3em 0;

            // .endorsement-photo {}
        }
    }

    #blue-area {
        background: var(--dark-blue);
        padding: 4em 2em;

        .logo-text {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 1.5em;
            margin-bottom: 5em;

            img {
                width: 100%;
                max-width: 800px;
            }
        }

        .state-logo {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 4em;

            img {
                width: 70vw;
                min-width: 200px;
                max-width: 400px;
            }
        }

        color: #fff;

        h3 {
            text-align: center;
            font-weight: 900;
            font-size: 1.6em;
            text-transform: uppercase;
        }

        p {
            margin: 0 auto;
            text-align: center;
            max-width: 500px;
            font-size: 1.25em;
        }

        
    }

    .henson-in-iowa {
            display: block;
            width: 100%;
            text-align: center;
            padding: 3em 1em;
            margin-bottom: 2em;

            img {
                max-width: 400px;
            }
        }

    .buttonz {
        max-width: 300px;
        margin: 0 auto;
        padding: 5em 1em;

        span {
            text-transform: uppercase;
        }
    }

    .district-map {
        padding: 3em 1em 1em 1em;

        span {
            text-transform: uppercase;
        }
    }

    .video {

        iframe {
            display: block;
            margin: 2em auto;
            width: 100%;
            max-width: 756px;
            height: 425px;
            border-radius: 1em;
        }
    }

    #bio {
        padding: 2em;
        max-width: 800px;
        margin: 0 auto;

        h4 {
            // text-align: center;
            font-weight: 900;
            font-size: 1.6em;
            text-transform: uppercase;
            margin-bottom: 2em;
        }

        p {
            font-size: 1.2em;
            font-weight: 400;
            line-height: 1.4em;
            margin-bottom: 2em;

            b, strong {
                background: var(--light-blue);
                color: #fff;
                font-weight: 400;
                padding: .3em .6em .3em 2em;
                // border-radius: 10em;
                border-radius: 0 .6em .6em .6em;
                background-image: url(./assets/images/star-white.svg);
                background-repeat: no-repeat;
                background-position: .6em center;
                background-size: 1em;
                display: inline-block;
                margin-bottom: .2em;
            }
        }
    }

    #app sub { 
        display: inline-block;
        width: 100%;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 3em;
    }

    @media ( max-width: 425px ) {
        #blue-area,
        .buttonz {
            font-size: .8em;
        }

        .buttonz,
        .district-map {
            grid-template-columns: 1fr !important;
            grid-auto-flow: unset !important;
        }

        #bio {
            font-size: .8em;
        }
    }

</style>
