<template>
    
    <div class="endorsement-photo">
        <div class="photo" :style="'background-image: url(' + photo + ')'">
            <div class="badge">
                <div class="bar">
                    <img src="./../assets/images/star-white-2.svg" />
                    <span>Endorsed</span>
                </div>
            </div>
        </div>
        <div class="name">
            <strong>{{ name }}</strong>
            <span>{{ title }}</span>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'EndorsementPhoto',

        props: {
            name: String,
            title: String,
            photo: String,
        },
    }
</script>

<style lang="scss">
    .endorsement-photo {
        display: inline-block;
        width: 275px;
        border-radius: 1rem;
        overflow: hidden;
        margin: .5rem;

        .photo {
            height: 328px;
            position: relative;
            background-color: #555;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;


            .badge {
                position: absolute;
                width: 100%;
                left: 0; bottom: 0;

                div {
                    margin: 0 auto 1.5rem auto;
                    width: max-content;
                    border-radius: 0 3rem 3rem 3rem;
                    background: var(--light-blue);
                    border: 1px solid #fff9;
                    padding: 1em 2rem;
                    color: #fff;

                    justify-content: center;

                    img {
                        width: 28px;
                    }

                    span {
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .name {
            padding: 1rem;
            background: #eee;
            text-align: center;
            height: 6rem;
            font-size: 1rem;

            strong {
                display: block;
                text-align: center;
                margin-bottom: .25rem;
                // text-transform: uppercase;
            }

            span {
                font-weight: 400;
            }
        }
    }
</style>
